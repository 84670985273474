import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";

@withI18next(["common"])
@page
class SearchRedirectEDS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {
    this.redirectEDS(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      this.redirectEDS(props);
    }
  }

  @autobind
  redirectEDS(props) {
    let { appStore, search } = props;
    let { globalConfig } = appStore;
    let params = { ...search };
    if (globalConfig["jumper.common.eds.issearchboxapi.isproxy"] === "1") {
      let edsURL =
        "https://searchbox.ebsco.com/search/?schemaId=search&custid=" +
        globalConfig["jumper.common.eds.custid"] +
        "&groupid=main&profid=eds&authtype=ip,guest&scope=site&site=eds-live&direct=true&bquery=" +
        encodeURIComponent(params.searchKey);
      let apiV3Params = {
        url: edsURL,
      };
      navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));
    } else {
      let form = document.createElement("form");
      form.id = "searchbox";
      form.action = "https://searchbox.ebsco.com/search/";
      form.style = "display:none";
      let element1 = document.createElement("input");
      element1.type = "hidden";
      element1.name = "schemaId";
      element1.value = "search";
      form.appendChild(element1);
      let element2 = document.createElement("input");
      element2.type = "hidden";
      element2.name = "custid";
      element2.value = globalConfig["jumper.common.eds.custid"];
      form.appendChild(element2);
      let element3 = document.createElement("input");
      element3.type = "hidden";
      element3.name = "groupid";
      element3.value = "main";
      form.appendChild(element3);
      let element4 = document.createElement("input");
      element4.type = "hidden";
      element4.name = "profid";
      element4.value = "eds";
      form.appendChild(element4);
      let element5 = document.createElement("input");
      element5.type = "hidden";
      element5.name = "authtype";
      element5.value = "ip,guest";
      form.appendChild(element5);
      let element6 = document.createElement("input");
      element6.type = "hidden";
      element6.name = "scope";
      element6.value = "site";
      form.appendChild(element6);
      let element7 = document.createElement("input");
      element7.type = "hidden";
      element7.name = "site";
      element7.value = "eds-live";
      form.appendChild(element7);
      let element8 = document.createElement("input");
      element8.type = "hidden";
      element8.name = "direct";
      element8.value = "true";
      form.appendChild(element8);
      let element9 = document.createElement("input");
      element9.id = "eb-sbb-search-input";
      element9.type = "text";
      element9.name = "bquery";
      element9.value = params.searchKey;
      form.appendChild(element9);
      let element10 = document.createElement("button");
      element10.type = "submit";
      form.appendChild(element10);
      document.body.appendChild(form);
      form.submit();
    }
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default SearchRedirectEDS;
